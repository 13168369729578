.ProfessionalExperience {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  /* background-color: #f8f8f8; */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column; 
  font-size: 16px;/* Display children in a column layout */
  box-shadow: -14px 14px 20px 0px rgba(0, 0, 0, 0.1);
}

.ProfessionalExperience:hover {
  background-color: #f8f8f8;
}

.CompanyInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.CompanyName {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
  flex: 1; /* Take up remaining space */
  font-size: 19px
}

.JobTitle {
  font-size: 1.1em;
  color: #666;
  text-align: center;
  flex: 2; /* Take up more space for center alignment */
  font-size: 19px;
}

.DateContainer {
  display: flex;
  align-items: center;
  color: #888;
  font-size: 0.9em;
  flex: 1; /* Take up remaining space */
  justify-content: flex-end; /* Align DateContainer to the right */
  font-size: 19px;
}

.DateSeparator {
  margin: 0 5px;
}

.JobResponsibilities {
  padding-left: 20px;
  margin-top: 10px;
}

.JobResponsibilities ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.JobResponsibilities li {
  margin-bottom: 5px;
  color: #444;
}


.Tech {
  margin-top: 10px;
}

.TechTitle {
  font-weight: bold;
  color: #666;
}

.TechList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.TechItem {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 3px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #555;
}


/* Media Query for Small Screens */
@media (max-width: 768px) {
  .CompanyInfo {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .JobTitle {
    text-align: center;
    margin: 5px 0;
  }

  .DateContainer {
    text-align: right;
  }

  .JobResponsibilities,
  .Tech {
    margin-top: 10px;
  }


}
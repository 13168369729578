.skillsSection {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
}

.skillCategory {
  margin-bottom: 20px;
}

.categoryTitle {
  font-size: 22px;
  color: #333;
}

.skillItems {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
}

.skillItem {
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.skillItem:hover {
  transform: translateY(-3px);
  background-color: #f8f8f8;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .skillsSection {
    padding-left: 0px;
    padding-top: 0px;
  }
}
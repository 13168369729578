/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap');
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap');
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');




.aboutSection {
  text-align: right;
  margin: 20px 0;
  border-radius: 5px;
  font-family: 'Inconsolata', monospace;

}


.firstname {
  font-size: 4em;
  color: #979797;
  font-family: 'Inconsolata', monospace;
}

.surname {
  font-size: 4em;
  font-weight: 600;
  color: #575757;
  font-family: 'Inconsolata', monospace;
}

.jobTitle {
  font-size: 1.5em;
  color: #666;
  font-family: 'Inconsolata', monospace;
}

.description {
  margin-top: 10px;
  color: #555;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .aboutSection{
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 0px;    
  }

  .aboutInfo {
    align-items: flex-start;
  }
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin: 0;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.columns-wrapper {
  display: flex;
  width: 95%;
  height: 100%;
  margin: 0;
}

.left-column {
  flex: 20%;
  padding: 15px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  min-width: 150px;
  min-height: 500px;
  box-shadow: -20px 20px 20px 0px rgba(0, 0, 0, 0.1);
}

.right-column {
  flex: 85%;
  padding: 20px;
  overflow-y: auto;
  min-width: 150px;
  box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.1);

}

.small-screen {
  display: none;
}


@media (min-width: 2000px) {
  .columns-wrapper {
    width: 80%;
  }
}


/* Media Query for Small Screens */
@media (max-width: 1000px) {
  .columns-wrapper {
    display: block;
  }

  .small-screen {
    display: flex;
    flex-direction: column;
  }

  .left-column {
    border-right: none;
    border-bottom: 1px solid #ccc;
    min-height: auto;
  }

  .right-column {
    padding-top: 20px;
    min-height: auto;
  }
}

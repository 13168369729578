@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin: 0;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.columns-wrapper {
  display: flex;
  width: 95%;
  height: 100%;
  margin: 0;
}

.left-column {
  flex: 20% 1;
  padding: 15px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  min-width: 150px;
  min-height: 500px;
  box-shadow: -20px 20px 20px 0px rgba(0, 0, 0, 0.1);
}

.right-column {
  flex: 85% 1;
  padding: 20px;
  overflow-y: auto;
  min-width: 150px;
  box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.1);

}

.small-screen {
  display: none;
}


@media (min-width: 2000px) {
  .columns-wrapper {
    width: 80%;
  }
}


/* Media Query for Small Screens */
@media (max-width: 1000px) {
  .columns-wrapper {
    display: block;
  }

  .small-screen {
    display: flex;
    flex-direction: column;
  }

  .left-column {
    border-right: none;
    border-bottom: 1px solid #ccc;
    min-height: auto;
  }

  .right-column {
    padding-top: 20px;
    min-height: auto;
  }
}

/* index.css or App.css */
/* index.css or App.css */
/* index.css or App.css */
/* index.css or App.css */




.About_aboutSection__1hjoP {
  text-align: right;
  margin: 20px 0;
  border-radius: 5px;
  font-family: 'Inconsolata', monospace;

}


.About_firstname__3S1w3 {
  font-size: 4em;
  color: #979797;
  font-family: 'Inconsolata', monospace;
}

.About_surname__YSf7G {
  font-size: 4em;
  font-weight: 600;
  color: #575757;
  font-family: 'Inconsolata', monospace;
}

.About_jobTitle__Pqcl7 {
  font-size: 1.5em;
  color: #666;
  font-family: 'Inconsolata', monospace;
}

.About_description__15HFP {
  margin-top: 10px;
  color: #555;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .About_aboutSection__1hjoP{
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 0px;    
  }

  .About_aboutInfo__tt8cv {
    align-items: flex-start;
  }
}
.Education_educationSection__JkhvC {
  padding: 10px;
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
}

.Education_schoolInfo__WGmEC {
  font-size: 18px;
  color: #333;
}

.Education_degree__2eHTn {
  font-size: 16px;
  color: #666;
}

.Education_date__roBXZ {
  color: #888;
  font-size: 16px;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .Education_educationInfo__2lx9N {
    align-items: flex-start;
  }

  .Education_educationSection__JkhvC {
    padding-top: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #e0e0e0;
  }
}
.Skills_skillsSection__32KzE {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
}

.Skills_skillCategory__2Qcc6 {
  margin-bottom: 20px;
}

.Skills_categoryTitle__1FwaZ {
  font-size: 22px;
  color: #333;
}

.Skills_skillItems__3C1sg {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  font-size: 16px;
}

.Skills_skillItem__2SWIr {
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
}

.Skills_skillItem__2SWIr:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background-color: #f8f8f8;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .Skills_skillsSection__32KzE {
    padding-left: 0px;
    padding-top: 0px;
  }
}
.Experience_experienceSection__3nvTm {
  margin: 20px 0;
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
}

.Company_ProfessionalExperience__2RusK {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  /* background-color: #f8f8f8; */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column; 
  font-size: 16px;/* Display children in a column layout */
  box-shadow: -14px 14px 20px 0px rgba(0, 0, 0, 0.1);
}

.Company_ProfessionalExperience__2RusK:hover {
  background-color: #f8f8f8;
}

.Company_CompanyInfo__1Yd3- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Company_CompanyName__k5d3z {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
  flex: 1 1; /* Take up remaining space */
  font-size: 19px
}

.Company_JobTitle__pRHWq {
  font-size: 1.1em;
  color: #666;
  text-align: center;
  flex: 2 1; /* Take up more space for center alignment */
  font-size: 19px;
}

.Company_DateContainer__ktkBy {
  display: flex;
  align-items: center;
  color: #888;
  font-size: 0.9em;
  flex: 1 1; /* Take up remaining space */
  justify-content: flex-end; /* Align DateContainer to the right */
  font-size: 19px;
}

.Company_DateSeparator__DFTkI {
  margin: 0 5px;
}

.Company_JobResponsibilities__2snpq {
  padding-left: 20px;
  margin-top: 10px;
}

.Company_JobResponsibilities__2snpq ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.Company_JobResponsibilities__2snpq li {
  margin-bottom: 5px;
  color: #444;
}


.Company_Tech__3_V_v {
  margin-top: 10px;
}

.Company_TechTitle__1wEfg {
  font-weight: bold;
  color: #666;
}

.Company_TechList__JLEdx {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.Company_TechItem__Cp0rK {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 3px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #555;
}


/* Media Query for Small Screens */
@media (max-width: 768px) {
  .Company_CompanyInfo__1Yd3- {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Company_JobTitle__pRHWq {
    text-align: center;
    margin: 5px 0;
  }

  .Company_DateContainer__ktkBy {
    text-align: right;
  }

  .Company_JobResponsibilities__2snpq,
  .Company_Tech__3_V_v {
    margin-top: 10px;
  }


}

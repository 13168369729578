.educationSection {
  padding: 10px;
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
}

.schoolInfo {
  font-size: 18px;
  color: #333;
}

.degree {
  font-size: 16px;
  color: #666;
}

.date {
  color: #888;
  font-size: 16px;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .educationInfo {
    align-items: flex-start;
  }

  .educationSection {
    padding-top: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #e0e0e0;
  }
}